import {ThemeProvider} from '@mui/material'
import {FC, PropsWithChildren} from 'react'
import {CUSTOM_THEME} from '../../themes/CustomThemes'
import {AppGridWrapper} from '../appGridWrapper/AppGridWrapper'
import {FeedbackMessage} from '../feedbackMessage/FeedbackMessage'
import {ShareFeedback} from '../shareFeedback/ShareFeedback'
import {MobileBanner} from '../mobileBanner/MobileBanner'

export const ThemeWrapper: FC<PropsWithChildren> = ({
    children
}) => (
    <ThemeProvider theme={CUSTOM_THEME}>
        <AppGridWrapper>
            {children}
            <FeedbackMessage/>
            <ShareFeedback/>
            <MobileBanner/>
        </AppGridWrapper>
    </ThemeProvider>
)