import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {Alert, Box, Stack, Tab, Tabs, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PaymentsIcon from '@mui/icons-material/Payments'
import React, {useState} from 'react'
import './OverallBudget.scss'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {CustomTabPanel} from '../../../homeTabs/HomeTabs'
import {BudgetForm} from './BudgetForm'
import { BudgetDetail } from './BudgetDetail'
import Spinner from '../../../spinner/Spinner'

export const OverallBudget = () => {
	const {toggleDrawer} = useToggleDrawerContext()
	const {overallBudget, loading} = useBudgetsContext()

	const [selectedTab, setSelectedTab] = useState<number>(0)

	return <Stack className='overallBudgetContainer'>
		<Box className='titleContainer'>
			<Box className='createTeamTitle'>
				<Box className='createTeamTitleIcon'>
					<PaymentsIcon fontSize='small'/>
				</Box>
				<Typography variant='h4'>Organization budget</Typography>
			</Box>
			<CloseIcon onClick={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')} cursor='pointer' className='closeIcon'/>
		</Box>

		<Box className='tabsContainer'>
			<Tabs value={selectedTab} onChange={(_, newValue: number) => setSelectedTab(newValue)}>
				<Tab label="BUDGET"/>
				<Tab label="HISTORY"/>
			</Tabs>
		</Box>
		<CustomTabPanel value={selectedTab} index={0}>
			<Box className='alertContainer'>
				<Alert severity="info">Costs are estimated. Actual bill may vary.</Alert>
			</Box>
			{loading ? <Spinner/> : (overallBudget ? <BudgetDetail budget={overallBudget}/> : <BudgetForm/>)}
		</CustomTabPanel>
		<CustomTabPanel value={selectedTab} index={1}>
			Overall budget history will go here...
		</CustomTabPanel>


	</Stack>
}