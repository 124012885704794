import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {Avatar, Button, Grid} from '@mui/material'
import Box from '@mui/material/Box'
import {AiModelIcon} from '../../../aiModelIcon/AiModelIcon'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import React, {useState} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {AIModelID} from '../../../../types/AiModel'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {Group} from '../../../../types/Group'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import '../users/EnabledAiModels.scss'
import './EnabledAiModelsByGoup.scss'
import {useGroupsContext} from '../../../../context/GroupsContext'

type EnabledAiModelsByGroupProps = {
	group: Group
}

export const EnabledAiModelsByGroup = ({group}: EnabledAiModelsByGroupProps) => {

	const {user} = useUser()
	const {aiModels} = useAiModelsContext()
	const {editGroup} = useGroupsContext()
	const {toggleDrawer} = useToggleDrawerContext()

	const [disabledAIModels, setDisabledAIModels] = useState<AIModelID[]>(group.disabledAIModels ?? [])

	const handleDisabledModelsChange = (enabled: boolean, aiModelID: AIModelID) => {
		setDisabledAIModels(previousValues => {
			if (enabled) previousValues = previousValues?.filter(modelId => modelId !== aiModelID)
			else previousValues = [...previousValues ?? [], aiModelID]
			return previousValues
		})
	}

	const saveUserInfoHandler = (event) => {
		TrackActionEvent('Edit group AI models', user?.id, {action: 'save_group_models', disabled_models: disabledAIModels})
		editGroup(group.hashKey, group.name, disabledAIModels).then(() =>
			toggleDrawer(false, 'EDIT_GROUP_AI_MODELS_DRAWER')(event)
		)
	}

	return <Grid container className='enabledAiModelsByGroupContainer'>
		<Grid item xs={12} container className='enabledAiModelsTitleWrapper'>
			<Grid item xs={1}>
				<Avatar className='editGroupModelsIcon'><AutoAwesomeIcon/></Avatar>
			</Grid>
			<Grid item xs={10} className='enabledAiModelsGroupName'>
				<Typography variant='h4'>Set up AI access for the team</Typography>
			</Grid>
			<Grid item xs={1} className='closerDrawerWrapper'>
				<CloseIcon onClick={toggleDrawer(false, 'EDIT_GROUP_AI_MODELS_DRAWER')}
				           cursor='pointer'
				           className='closeIcon'/>
			</Grid>
		</Grid>
		<Grid item xs={12}>
			<Typography variant='body1' className='enabledAiModelsExplanation'>
				Control AI access for the whole team. NOTE Team settings override individual user settings.
			</Typography>

			<Box className='enabledAiModelsListContainer'>
				{aiModels.filter(aiModel => aiModel.isEnabled).map((aiModel) =>
					<Grid container className={`aiModelRow ${aiModel.isEnabled ? '' : 'disabled'}`}
					      key={aiModel.id}>
						<Grid item md={11} xs={10}>
							<Box><AiModelIcon modelId={aiModel.id}/></Box>
							<Typography>{aiModel.name}</Typography>
						</Grid>
						<Grid item md={1} xs={2}>
							<Switch
								disabled={!aiModel.isEnabled}
								checked={aiModel.isEnabled && (!disabledAIModels?.length || disabledAIModels?.find(disabledModel => disabledModel === aiModel.id) === undefined)}
								onChange={(event) => handleDisabledModelsChange(event.target.checked, aiModel.id)}
							/>
						</Grid>
					</Grid>)}
			</Box>
		</Grid>
		<Box className='saveDisabledModelsButtonContainer'>
			<Button className='saveChatButton' variant='contained' onClick={saveUserInfoHandler}>
				Save
			</Button>
		</Box>
	</Grid>

}