import {Box, Grid, Tab, Tabs} from '@mui/material'
import {NarusLogo} from '../narusLogo/NarusLogo'
import {UserProfileMenu} from '../headerMenu/profileMenu/ProfileMenu'
import {TabLabelUserLogs} from './TabLabelUserLogs'
import {getAdminTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useAdminNavigationContext} from '../../context/AdminNavigationContextProvider'
import {useCallback} from 'react'
import {getBaseConfigByTab} from '../../utils/adminNavigationUtils'

export const AdminHeader = () => {

	const {user} = useUser()
	const {selectedTab, navigate} = useAdminNavigationContext()

	const selectTabHandler = useCallback((newValue: number) => {
		TrackActionEvent('Admin tab', user?.id, {section: getAdminTabForAnalytics(newValue)})
		navigate(getBaseConfigByTab(newValue))
	}, [user?.id, navigate])

	return <Grid container className='adminContainer adminHeaderContainer admin'>
		<Grid xs={12} className='headerWrapper' item>
			<Box className='header admin'>
				<Box className='headerWelcome' onClick={() => selectTabHandler(0)}>
					<NarusLogo height={51} width={51}/>
				</Box>
				<UserProfileMenu/>
			</Box>
		</Grid>

		<Grid xs={12} item>
			<Tabs value={selectedTab} onChange={(_, newValue: number) => selectTabHandler(newValue)}
			      className='adminTabContainer' variant='scrollable'>
				<Tab label='Overview'/>
				<Tab label={<TabLabelUserLogs/>}/>
				<Tab label='Configuration'/>
			</Tabs>
		</Grid>
	</Grid>
}