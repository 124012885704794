import {RedirectToSignIn} from '@clerk/clerk-react'
import {useErrorBoundary} from 'react-error-boundary'
import {Link} from '@mui/material'
import './ErrorSection.scss'

export const ErrorSection = ({error}) => {

    const {resetBoundary} = useErrorBoundary()

    if (error.clerkError || error.cause === 401 || error.message === 'Request failed with status code 401') {
        console.error('Auth error: ', {error})
        return <RedirectToSignIn/>
    }

    return <>
        <p className='error'>Oops, something went wrong :(</p>
        <p className='errorMessage'>{error?.message || error?.errors[0]?.message}</p>
        <Link className='errorLink' onClick={resetBoundary}>Try again</Link>
    </>
}
