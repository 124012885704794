import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo} from 'react'
import {UsageStatistic, UserUsageStatistic} from '../types/UserUsageStatistic'
import {AIModelCost, StatisticByModel, useStatistics} from '../hooks/useStatistics'
import {DateRange} from 'rsuite/DateRangePicker'

interface StatisticsContextProviderProps {
	children: ReactNode
}

export interface StatisticsContextValue {
	loading: boolean
	usageStatistics: UsageStatistic | undefined
	top5UsersStatistics: UserUsageStatistic[]
	totalPromptsByModel: StatisticByModel[]
	totalUsersByModel: StatisticByModel[]
	top5ModelsByCost: AIModelCost[]
	setDateRangeFilter: Dispatch<SetStateAction<DateRange | undefined>>
}

const DEFAULT_STATISTIC_CONTEXT: StatisticsContextValue = {
	loading: false,
	usageStatistics: undefined,
	top5UsersStatistics: [],
	totalPromptsByModel: [],
	totalUsersByModel: [],
	top5ModelsByCost: [],
	setDateRangeFilter: (dateRangeFilter: SetStateAction<DateRange | undefined>) => {}
}

export const StatisticContext = createContext<StatisticsContextValue>(DEFAULT_STATISTIC_CONTEXT)

export const useStatisticsContext = () => useContext(StatisticContext)

export const StatisticsContextProvider = ({children}: StatisticsContextProviderProps) => {
	const {
		loading,
		usageStatistics,
		top5UsersStatistics,
		totalPromptsByModel,
		totalUsersByModel,
		top5ModelsByCost,
		setDateRangeFilter,
	} = useStatistics()

	const value: StatisticsContextValue = useMemo(() => ({
			loading,
			usageStatistics,
			top5UsersStatistics,
			totalPromptsByModel,
			totalUsersByModel,
			top5ModelsByCost,
			setDateRangeFilter
		}),
		[loading, usageStatistics, top5UsersStatistics, totalUsersByModel, totalPromptsByModel, top5ModelsByCost, setDateRangeFilter])

	return <StatisticContext.Provider value={value}>
		{children}
	</StatisticContext.Provider>
}