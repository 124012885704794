import React, {useEffect} from 'react'
import {PageView} from '../../service/SegmentService'
import {AdminErrorScreen} from './error/AdminErrorScreen'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {AdminHeader} from './AdminHeader'
import {Outlet} from 'react-router-dom'
import {Grid} from '@mui/material'
import './Admin.scss'
import {NoBudgetAlert} from './NoBudgetAlert'
import {useBudgetsContext} from '../../context/BudgetContext'

export const Admin = () => {

    const {userInfo} = useUserInfoContext()
    const isAdmin = userInfo?.isAdmin
    const {isOverallBudgetFinished} = useBudgetsContext()


    useEffect(() => {
        if (isAdmin) PageView('Narus admin')
    }, [isAdmin])

    return <>
        {isAdmin ? <>
            <NoBudgetAlert/>
            <AdminHeader/>
            <Grid container className={`adminContainer adminTabWrapper ${isOverallBudgetFinished ? 'budgetFinished' : ''}`}>
                <Grid xs={12} item>
                    <Outlet/>
                </Grid>
            </Grid>
        </> : <AdminErrorScreen/>}
    </>
}