import {AIModel, AIModelID, ALL_AI_MODEL_IDS, allAIModels} from '../types/AiModel'
import {UserInfo} from '../types/UserInfo'
import {Group} from '../types/Group'
import {hasProp} from '../utils/genericUtils'

export const isModelEnabledForUser = (aiModelID: AIModelID, aiModels: AIModel[], userInfo?: UserInfo, userGroup?: Group): boolean => {
	const aiModelEnabled = !!aiModels.find(hasProp('id', aiModelID))?.isEnabled
	const userInfoEnabled = !!userInfo?.enabledAIModels?.includes(aiModelID)
	const userInfoDisabled = !!userInfo?.disabledAIModels?.includes(aiModelID)
	const userGroupDisabled = !!userGroup?.disabledAIModels?.includes(aiModelID)
	return aiModelEnabled && !userInfoDisabled && (!userGroupDisabled || (userGroupDisabled && userInfoEnabled))
}

export const getAiModelsList = (storedAiModels: AIModel[]) => {
	const storedModelIds = new Set(storedAiModels.map((model) => model.id))
	return allAIModels
		.filter((aiModel: AIModel) => !storedModelIds.has(aiModel.id))
		.map((filterModel: AIModel) => ({label: filterModel.id, value: filterModel.id}))
}

export const isAiModel = (value: string): value is AIModelID =>
	ALL_AI_MODEL_IDS.some(id => id === value)