import {useEffect} from 'react'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@mui/material'
import './BlockingDialog.scss'
import {useNavigate} from 'react-router-dom'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

type BlockingDialogProps = {
	open: boolean
	isAdmin: boolean
	isPausedAfterTrial: boolean
}

export const BlockingDialog = ({open, isAdmin, isPausedAfterTrial}: BlockingDialogProps) => {

	const navigate = useNavigate()
	const {user} = useUser()

	const getTrialTextContent = () => {
		if (isAdmin) {
			return <Box>
				<DialogContentText className='adminTextContent'>Your free trial has expired. Convert to a paid plan to continue using Narus.</DialogContentText>
				<DialogContentText className='adminTextContent'>To restore access to your Company workspace use one of the following options.</DialogContentText>
			</Box>
		}
		return <Typography className='userTextContent'>Your free trial has expired. Please <span className='boldText'>contact your administrator</span> to have access to a new plan
			continue to have access to all Narus features.</Typography>
	}

	const getTextContent = () =>
		<Typography className='userTextContent'>You do not have a valid license. Check our available plans or create a new account.</Typography>

	const selectPlanClickHandler = () => {
		TrackActionEvent('Blocking screen action', user?.id, {action: 'select_plan'})
		navigate('/pricing')
	}

	const contactSalesClickHandler = () => {
		TrackActionEvent('Blocking screen action', user?.id, {action: 'contact_sales'})
		//TODO complete contact sales logic
	}

	useEffect(() => {
		PageView('Blocking screen')
	})

	return <Dialog open={open} className='trialDialogContainer'>
		<DialogTitle className='trialDialogTitle'>
			{isPausedAfterTrial ? 'Your trial has expired' : 'Looks like you don’t have a valid license'}
		</DialogTitle>
		<DialogContent className='trialDialogContent'>
			{isPausedAfterTrial ? getTrialTextContent() : getTextContent()}
		</DialogContent>
		{isAdmin ? <DialogActions className='trialDialogActionsContainer'>
			<Button variant='outlined' onClick={contactSalesClickHandler}>Contact sales</Button>
			<Button variant='contained' onClick={selectPlanClickHandler}>Select a plan</Button>
		</DialogActions> : <></>}
	</Dialog>
}