import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Budget} from '../types/Budget'
import {createBudget, deleteBudget, getOverallBudget, updateBudget} from '../service/persistenceService'
import {useUserContext} from './UserContext'
import {useFeedbackContext} from './FeedbackContext'

export interface BudgetsContextValue {
	loading: boolean
	isOverallBudgetFinished: boolean
	overallBudget: Budget | undefined
	teamBudgets: Budget[]
	saveBudget: (budget: Partial<Budget>) => Promise<Budget | undefined>,
	updateBudgetAmount: (budget: Budget, amount: number) => Promise<Budget | undefined>
	removeBudget: (budget: Budget) => void
}

const DEFAULT_BUDGETS_CONTEXT: BudgetsContextValue = {
	loading: false,
	isOverallBudgetFinished: false,
	overallBudget: undefined,
	teamBudgets: [],
	saveBudget: (budget: Partial<Budget>) => Promise.resolve(undefined),
	updateBudgetAmount: (budget: Budget, amount: number) => Promise.resolve(undefined),
	removeBudget: (budget: Budget) => {}
}

export const BudgetsContext = createContext<BudgetsContextValue>(DEFAULT_BUDGETS_CONTEXT)

export const useBudgetsContext = () => useContext(BudgetsContext)

export const BudgetsContextProvider: FC<PropsWithChildren> = ({children}) => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [overallBudget, setOverallBudget] = useState<Budget>()
	const [teamBudgets, setTeamBudgets] = useState<Budget[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [isOverallBudgetFinished, setIsOverallBudgetFinished] = useState<boolean>(false)


	const saveBudget = useCallback((budget: Partial<Budget>): Promise<Budget | undefined> => {
		return createBudget(token, budget).then(budget => {
			if (budget.type === 'Organization') setOverallBudget(budget)
			else setTeamBudgets(previousTeamBudgets => [...previousTeamBudgets, budget])
			showFeedback('Budget created', '', 'success')
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to create a budget. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback])

	const updateBudgetAmount = useCallback(({hashKey}: Budget, amount: number): Promise<Budget | undefined> => {
		return updateBudget(token, hashKey, amount).then(budget => {
			if (budget.type === 'Organization') setOverallBudget(budget)
			else setTeamBudgets(previousTeamBudgets => [...previousTeamBudgets, budget])
			showFeedback('Budget updated', '', 'success')
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to update the budget. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback])

	useEffect(() => {
		setLoading(true)
		getOverallBudget(token)
			.then(overallBudget => {
				setOverallBudget(overallBudget)
				setIsOverallBudgetFinished(overallBudget ? overallBudget?.spent > overallBudget?.amount : false)
			})
			.finally(() => setLoading(false))
	}, [token, showFeedback])

	const removeBudget = useCallback(({hashKey}: Budget): void => {
		deleteBudget(token, hashKey).then(budget => {
			setOverallBudget(undefined)
			showFeedback('Budget removed', '', 'success')
		}).catch(() => {
			showFeedback('Error', 'Something went wrong trying to remove the budget. Try again reloading the page', 'error')
			return undefined
		})
	}, [showFeedback, token])

	const value: BudgetsContextValue = useMemo(() => ({loading, isOverallBudgetFinished, overallBudget, teamBudgets, saveBudget, updateBudgetAmount, removeBudget}),
		[loading, isOverallBudgetFinished, overallBudget, teamBudgets, saveBudget, updateBudgetAmount, removeBudget])

	return <BudgetsContext.Provider value={value}>
		{children}
	</BudgetsContext.Provider>
}