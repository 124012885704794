import {PromptsContextProvider} from '../../context/PromptsContext'
import {SearchContextProvider} from '../../context/SearchContext'
import {PromptCreationContextProvider} from '../../context/PromptCreationContext'
import {ToggleDrawerContextProvider} from '../../context/ToggleDrawerContext'
import {AiModelsContextProvider} from '../../context/AIModelsContext'
import {ChatsContextProvider} from '../../context/ChatsContext'
import {ChatMessagesContextProvider} from '../../context/ChatMessagesContext'
import {Outlet} from 'react-router-dom'
import {TrialBoundary} from '../trial/TrialBoundary'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {FeedbackContextProvider} from '../../context/FeedbackContext'
import {ThemeWrapper} from '../theme/ThemeWrapper'
import {StripeSubscriptionsContextProvider} from '../../context/StripeSubscriptionsContext'
import {UserGroupContextProvider} from '../../context/UserGroupContext'
import {UserBudgetRemainingContextProvider} from '../../context/UserBudgetRemaining'

export const HomeWrapper = () => {
	return (
		<UserOrganizationContextProvider>
			<FeedbackContextProvider>
				<ThemeWrapper>
					<PromptsContextProvider>
						<UserInfoContextProvider>
							<UserGroupContextProvider>
								<UserBudgetRemainingContextProvider>
									<StripeSubscriptionsContextProvider>
										<AiModelsContextProvider>
											<SearchContextProvider>
												<ChatMessagesContextProvider>
													<PromptCreationContextProvider>
														<ChatsContextProvider>
															<ToggleDrawerContextProvider>
																<TrialBoundary>
																	<Outlet/>
																</TrialBoundary>
															</ToggleDrawerContextProvider>
														</ChatsContextProvider>
													</PromptCreationContextProvider>
												</ChatMessagesContextProvider>
											</SearchContextProvider>
										</AiModelsContextProvider>
									</StripeSubscriptionsContextProvider>
								</UserBudgetRemainingContextProvider>
							</UserGroupContextProvider>
						</UserInfoContextProvider>
					</PromptsContextProvider>
				</ThemeWrapper>
			</FeedbackContextProvider>
		</UserOrganizationContextProvider>
	)
}