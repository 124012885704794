import {GridActionsCellItem, GridColDef, GridRowId, GridRowParams} from '@mui/x-data-grid'
import React, {useCallback, useMemo, useState} from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import {AIModelID} from '../../../../types/AiModel'
import {Avatar, Box, Chip, Drawer, Grid, Typography} from '@mui/material'
import {DataGridTable} from '../../DataGridTable'
import './UsersList.scss'
import {AiModelIcon} from '../../../aiModelIcon/AiModelIcon'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {CustomTooltip} from './CustomTooltip'
import {EnabledAiModels} from './EnabledAiModels'
import {UserInfo} from '../../../../types/UserInfo'
import Spinner from '../../../spinner/Spinner'
import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {stringAvatar} from '../../../../helpers/AvatarHelpers'
import {isModelEnabledForUser} from '../../../../helpers/AiModelHelper'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {getUserGroup} from '../../../../helpers/UserHelpers'

export const UsersList = () => {

	const {loading, usersInfo} = useUsersAccessContext()
	const {aiModels} = useAiModelsContext()
	const {displayDrawerEditUser, toggleDrawer} = useToggleDrawerContext()
	const {groups, allGroupsMembers} = useGroupsContext()
	const [selectedUserInfo, setSelectedUserInfo] = useState<UserInfo>()

	const generateAiModelChips = useCallback((userInfo: UserInfo) => {
		const enabledModels = aiModels?.filter(aiModel => aiModel.isEnabled)

		const group = getUserGroup(userInfo, allGroupsMembers, groups)
		const enabledModelIdsForUser = enabledModels
			.filter(aiModel => isModelEnabledForUser(aiModel.id, aiModels, userInfo, group))
			.map(aiModel => aiModel.id)
		if (!enabledModelIdsForUser?.length) return <></>

		const tooltipForMoreAIModels = enabledModelIdsForUser.slice(2).map((enabledModelId: AIModelID) => <Box className='tooltipModelNameContainer' key={enabledModelId}>
			<AiModelIcon modelId={enabledModelId}/>{enabledModels?.find(model => model.id === enabledModelId)?.name}
		</Box>)
		return <>
			{enabledModelIdsForUser.slice(0, 2)?.map((enabledModelIdForUser: AIModelID) => <Chip key={enabledModelIdForUser}
				label={enabledModels?.find(({id}) => id === enabledModelIdForUser)?.name}
				variant='outlined' className={`chipColor${enabledModelIdForUser}`}
				avatar={<AiModelIcon modelId={enabledModelIdForUser} className='aiIconModelDisabled'/>}/>)}
			{enabledModelIdsForUser.length > 2 ? <CustomTooltip title={tooltipForMoreAIModels}
			>
				<Typography>+{enabledModelIdsForUser.length - 2} more</Typography>
			</CustomTooltip> : <></>}
		</>
	}, [aiModels, groups, allGroupsMembers])

	const open = useCallback((userId: GridRowId, event: React.MouseEvent) => {
		setSelectedUserInfo(usersInfo.find(userInfo => userInfo.userId === userId.toString()))
		return toggleDrawer(true, 'EDIT_USER_DRAWER')(event)
	}, [toggleDrawer, usersInfo])

	const columns: GridColDef[] = useMemo(() => [
		{
			field: 'userFullName', headerName: 'Name', flex: 1, cellClassName: 'userNameCell', renderCell: (params) => <Box className='userNameCell' key={params.id}>
				<Avatar {...stringAvatar(params.value)} src={params.row.imageUrl ?? ''}/>
				<Typography variant='body2' className='userFullName'>{params.value ?? params.id}</Typography>
			</Box>
		},
		{field: 'email', headerName: 'Email', flex: 1, cellClassName: 'userMailCell'},
		{
			field: 'disabledModels',
			headerName: 'Access to AI models',
			cellClassName: 'disabledModelsCell',
			flex: 3,
			renderCell: (params) => generateAiModelChips(params.row)
		},
		{
			field: 'actions', type: 'actions', headerName: 'Actions', width: 80, getActions: (params: GridRowParams) => [
				<GridActionsCellItem
					icon={<SettingsIcon className='actionIcon'/>}
					label="Configure models"
					onClick={(event) => open(params.id, event)}
				/>]
		}
	], [open, generateAiModelChips])

	return <Grid container>
		<Grid item xs={12} className='usersListsContainer'>
			<Typography variant='h3'>User management</Typography>
			<DataGridTable rows={usersInfo} columns={columns} loading={loading} analyticEventName='User access table'
						   pageSize={10} currentTable='USERS_LIST'/>
			<Drawer anchor='right' open={displayDrawerEditUser}
					onClose={toggleDrawer(false, 'EDIT_USER_DRAWER')}>
				{selectedUserInfo ? <EnabledAiModels userInfo={selectedUserInfo}/> : <Spinner/>}
			</Drawer>
		</Grid>
	</Grid>
}