import {Grid} from '@mui/material'
import {CustomDateRangePicker} from './CustomDateRangePicker'
import {CompanyWorkspace} from './charts/CompanyWorkspace'
import {AiUsageChart} from './charts/AiUsageChart'
import {CostPerAIModel} from './charts/CostPerAIModel'
import {UsageTable} from './charts/UsageTable'

export const AdminOverview = () => <>
    <Grid item xs={12} className='datePickerContainer'>
        <CustomDateRangePicker currentTab='OVERVIEW'/>
    </Grid>
    <Grid container item xs={12}>
        <Grid item xs={3}>
            <CompanyWorkspace/>
        </Grid>
        <Grid item xs={9} container>
            <Grid item xs={6}>
                <AiUsageChart/>
            </Grid>
            <Grid item xs={6}>
                <CostPerAIModel/>
            </Grid>
            <Grid item xs={12}>
                <UsageTable/>
            </Grid>
        </Grid>
    </Grid>
</>