import {GridCsvExportMenuItem, GridPrintExportMenuItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridToolbarFilterButton} from '@mui/x-data-grid'
import {GridToolbarQuickFilter} from '@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter'
import {Box} from '@mui/material'
import {TrackActionEvent} from '../../../service/SegmentService'
import {ReactNode} from 'react'
import {useUser} from '@clerk/clerk-react'
import {GridExportDisplayOptions} from '@mui/x-data-grid/components/toolbar/GridToolbarExport'
import {TableName} from '../DataGridTable'

export type CustomMUIDataGridExportButtonProps = {
	userId: string
	options?: GridExportDisplayOptions
	hideMenu?: boolean
	exportType: string
	children: ReactNode
	currentTable: TableName
}

type DataGridCustomToolbarProps = {
	currentTable: TableName
}

export const DataGridCustomToolbar = ({currentTable}: DataGridCustomToolbarProps) => {
	const {user} = useUser()

	return <GridToolbarContainer className='usageTableToolbarContainer'>
		<GridToolbarColumnsButton/>
		<GridToolbarFilterButton/>
		<GridToolbarExport userId={user?.id} currentTable={currentTable}/>
		<GridToolbarQuickFilter/>
	</GridToolbarContainer>
}

const CustomExportButton = ({exportType, userId, children, currentTable}: CustomMUIDataGridExportButtonProps) => {

	const eventNameMapping: Record<TableName, string> = {
		'AUDITING_LOGS': 'Auditing logs table',
		'USER_USAGE': 'Usage table',
		'USERS_LIST': 'User access table',
		'GROUP_MEMBERS': 'Group members'
	}

	return <Box onClick={() => TrackActionEvent(eventNameMapping[currentTable], userId, {action: 'export', exportFormat: exportType})}>
		{children}
	</Box>
}

const GridToolbarExport = ({userId, currentTable}) =>
	<GridToolbarExportContainer>
		<CustomExportButton exportType='csv' userId={userId} currentTable={currentTable}>
			<GridCsvExportMenuItem/>
		</CustomExportButton>
		<CustomExportButton exportType='pdf' userId={userId} currentTable={currentTable}>
			<GridPrintExportMenuItem/>
		</CustomExportButton>
	</GridToolbarExportContainer>