import {Button, Checkbox, Divider, FormControlLabel, Grid, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import './PricingTable.scss'
import Box from '@mui/material/Box'
import {NarusLogo} from '../narusLogo/NarusLogo'
import {ChangeEvent, useEffect, useState} from 'react'
import {UserButton, useUser} from '@clerk/clerk-react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {ThemeWrapper} from '../theme/ThemeWrapper'

export const PricingTable = () => {

	const {user} = useUser()
	const [seatsNumber, setSeatsNumber] = useState<number>()
	const [selectedPlanPrice, setSelectedPlanPrice] = useState<number>(0)
	const [periodSelected, setPeriodSelected] = useState<string>('annual')

	useEffect(() => {
		PageView('Pricing')
	})

	const increaseSeatsNumber = () => {
		setSeatsNumber(previousSeatsNumber => {
			if (!previousSeatsNumber) return 1
			return previousSeatsNumber + 1
		})
	}

	const decreaseSeatsNumber = () => {
		setSeatsNumber(previousSeatsNumber => {
			if (!previousSeatsNumber) return 1
			return previousSeatsNumber > 1 ? previousSeatsNumber - 1 : 1
		})
	}

	const seatsNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const seatsNumber = Number(event.target.value)
		if (seatsNumber < 1) return setSeatsNumber(1)
		setSeatsNumber(seatsNumber)
	}

	const paymentClickHandler = () => {
		TrackActionEvent('Pricing table', user?.id, {action: 'go_payment'})
	}

	return <ThemeWrapper>
		<Grid container className='pricingContainer'>
			<Grid xs={12} className='headerWrapper' item>
				<Box className='header'>
					<Box className='headerWelcome'>
						<NarusLogo/>
					</Box>
					<UserButton showName={false}/>
				</Box>
			</Grid>

			<Grid item xs={12} className='titleRow'>
				<Typography variant='h3'>Select your team plan</Typography>
			</Grid>

			<Grid item xs={12} className='titleRow'>
				<ToggleButtonGroup size="small" exclusive className='periodSelector' value={periodSelected}>
					<ToggleButton value="monthly" onClick={() => setPeriodSelected('monthly')}>
						Monthly
					</ToggleButton>
					<ToggleButton value="annual" onClick={() => setPeriodSelected('annual')}>
						Annual
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>

			<Grid item xs={12} className='plansRow'>
				<Paper className={`planContainer ${selectedPlanPrice === 3 ? 'selected' : ''}`}>
					<Box className='titleContainer'>
						<FormControlLabel control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>} onClick={() => setSelectedPlanPrice(3)}
															checkedIcon={<CheckCircleIcon/>} checked={selectedPlanPrice === 3}/>} className='planTitle' label='Growth'/>
					</Box>
					<Typography className='price'>${periodSelected === 'annual' ? 3 : (3 * 1.15).toFixed(2)} user/month</Typography>

					<Typography className='benefitTitle'>For knowledge workers:</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Prompt optimization</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Prompt libraries</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Use multiple AI models</Typography>
					</Box>


					<Typography className='benefitTitle'>For administrator:</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Connect your own APIs</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>AI cost monitoring</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>User activity by model</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Audit log (3 months of audit history)</Typography>
					</Box>
				</Paper>
				<Paper className={`planContainer ${selectedPlanPrice === 5 ? 'selected' : ''}`}>
					<Box className='titleContainer'>
						<FormControlLabel control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>} onClick={() => setSelectedPlanPrice(5)}
															checkedIcon={<CheckCircleIcon/>} checked={selectedPlanPrice === 5}/>} label='Scale' className='planTitle'/>
					</Box>
					<Typography className='price'>${periodSelected === 'annual' ? 5 : (5 * 1.15).toFixed(2)} user/month</Typography>

					<Typography className='benefitTitle'>Everything in Growth plus:</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Safeguarding</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Unlimited LLM's</Typography>
					</Box>

					<Typography className='benefitTitle'>Services</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Advanced SLA</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Priority support queue</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Onboarding package for your organisation</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Dedicated customer success manager</Typography>
					</Box>
				</Paper>
				<Divider className='verticalDivider'/>
				<Box className='summaryContainer'>
					<Typography className='title'>Seats</Typography>
					<Box className='seatsRow'>
						<TextField variant='outlined' placeholder='Number of seats' value={seatsNumber} onChange={seatsNumberChangeHandler}/>
						<Button className='subtractButton' variant='outlined' onClick={decreaseSeatsNumber}>
							<RemoveIcon className='subtractIcon'/>
						</Button>
						<Button className='addButton' variant='outlined' onClick={increaseSeatsNumber}>
							<AddIcon className='addIcon'/>
						</Button>
					</Box>

					<Divider className='horizontalDivider'/>
					<Typography className='title summaryTitle'>Summary</Typography>
					{seatsNumber && seatsNumber > 250 ?
						<>
							<Typography className='contactSalesText'>It looks like you have a big team, please contact with our sales team to get support.</Typography>
							<Button variant='outlined' className='contactSalesButton'>Contact sales</Button>
						</>
						: <>
							<Typography>{selectedPlanPrice ? `Plan ${selectedPlanPrice === 3 ? 'Growth' : 'Scale'} paid ${periodSelected === 'annual' ? 'annually' : 'monthly'}` : ''}</Typography>
							<Typography>{seatsNumber ? `${seatsNumber} seats` : ''}</Typography>
							<Typography className='price'>{((seatsNumber ?? 0) * selectedPlanPrice * (periodSelected === 'annual' ? 12 : 1.15)).toLocaleString('en-US', {
								style: 'currency',
								currency: 'USD'
							})}</Typography>
						</>}


					<Box className='buttonContainer'>
						<Button variant='contained' className='continueButton' disabled={!seatsNumber || !selectedPlanPrice || (!!selectedPlanPrice && !!seatsNumber && seatsNumber > 250)} onClick={paymentClickHandler}>Payment</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	</ThemeWrapper>
}