import {RedirectToSignIn, SignedIn, SignedOut} from '@clerk/clerk-react'
import {UserContextProvider} from './context/UserContext'
import {Outlet} from 'react-router'

export const AppPrivateWrapper = () => (
    <>
        <SignedIn>
            <UserContextProvider>
                <Outlet/>
            </UserContextProvider>
        </SignedIn>
        <SignedOut>
            <RedirectToSignIn/>
        </SignedOut>
    </>
)
