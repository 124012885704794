import {Button, Drawer, Grid, Typography} from '@mui/material'
import {useGroupsContext} from '../../../../context/GroupsContext'
import './GroupSettings.scss'
import Box from '@mui/material/Box'
import {AiModelIcon} from '../../../aiModelIcon/AiModelIcon'
import React, {useEffect, useState} from 'react'
import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {isModelEnabledForUser} from '../../../../helpers/AiModelHelper'
import {AIModel, AIModelID} from '../../../../types/AiModel'
import {UserInfo} from '../../../../types/UserInfo'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {GroupUsersByModelAccess} from './GroupUsersByModelAccess'
import {EnabledAiModelsByGroup} from './EnabledAiModelsByGroup'
import Spinner from '../../../spinner/Spinner'

export type UsersByAiModelAccess = {
	aiModel: AIModel
	userInfoWithModelAccess: UserInfo[]
}

export const GroupSettings = () => {

	const {selectedGroup, groupMembers} = useGroupsContext()
	const {aiModels} = useAiModelsContext()
	const {usersInfo} = useUsersAccessContext()
	const {toggleDrawer, displayDrawerShowGroupAiModelAccess, displayDrawerEditGroupAiModels} = useToggleDrawerContext()

	const [usersByModelsAccess, setUsersByModelsAccess] = useState<UsersByAiModelAccess[]>([])
	const [usersByModelAccess, setUsersByModelAccess] = useState<UsersByAiModelAccess>()

	useEffect(() => {
		const groupMemberIds = groupMembers.map(groupMember => groupMember.userId)
		const groupUsersInfo = usersInfo.filter(userInfo => groupMemberIds.includes(userInfo.userId))

		const usersByAIModelAccess = aiModels.filter(aiModel => aiModel.isEnabled).map(aiModel => {
			const userInfoWithModelAccess = groupUsersInfo.filter(userInfo =>
				isModelEnabledForUser(aiModel.id, aiModels, userInfo, selectedGroup)
			)
			return {aiModel, userInfoWithModelAccess}
		})
		setUsersByModelsAccess(usersByAIModelAccess)
	}, [groupMembers, usersInfo, aiModels, selectedGroup])

	const seeDetailsHandler = (event: React.MouseEvent, aiModelId: AIModelID) => {
		setUsersByModelAccess(usersByModelsAccess.find(usersByModelAccess => usersByModelAccess.aiModel.id === aiModelId))
		return toggleDrawer(true, 'SHOW_GROUP_AI_MODEL_ACCESS')(event)
	}

	return <Grid container className='groupSettings'>
		<Grid item xs={12} className='titleContainer' container>
			<Typography className='title'>AI Access</Typography>
			<Button variant='outlined' className='setupAccessButton' onClick={toggleDrawer(true, 'EDIT_GROUP_AI_MODELS_DRAWER')} >Set up AI access</Button>
		</Grid>

		{usersByModelsAccess.map((usersByModelAccess, index) =>
			<Grid container className='aiModelRow' key={`${usersByModelAccess.aiModel.id}-${index}`}>
				<Grid item xs={4}>
					<Box><AiModelIcon modelId={usersByModelAccess.aiModel.id}/></Box>
					<Typography>{usersByModelAccess.aiModel.name}</Typography>
				</Grid>
				<Grid item xs={6} className='groupAiModelCount'>
					<Typography>User
						access: {usersByModelAccess?.userInfoWithModelAccess?.length ?? 0}</Typography>
				</Grid>
				<Grid item xs={2} className='groupAiModelDetailButton'>
					{usersByModelAccess?.userInfoWithModelAccess?.length ?
						<Button variant='text' onClick={(event) => seeDetailsHandler(event, usersByModelAccess.aiModel.id)}>Details</Button> :
						<></>
					}
				</Grid>
			</Grid>
		)}
		<Drawer anchor='right' open={displayDrawerShowGroupAiModelAccess}
		        onClose={toggleDrawer(false, 'SHOW_GROUP_AI_MODEL_ACCESS')}>
			{usersByModelAccess ? <GroupUsersByModelAccess usersByModelAccess={usersByModelAccess}/> : <></>}
		</Drawer>
		<Drawer anchor='right' open={displayDrawerEditGroupAiModels}
		        onClose={toggleDrawer(false, 'EDIT_GROUP_AI_MODELS_DRAWER')}>
			{selectedGroup ? <EnabledAiModelsByGroup group={selectedGroup}/> : <Spinner/>}
		</Drawer>
	</Grid>
}