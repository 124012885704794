import {Box, Button, Drawer, Grid, IconButton, Tooltip, Typography} from '@mui/material'
import React, {useState} from 'react'
import './BudgetControl.scss'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {EmptyGroups} from '../../../emptySection/EmptyGroups'
import InfoIcon from '@mui/icons-material/Info'
import FunctionsIcon from '@mui/icons-material/Functions'
import {GroupCreation} from '../groups/GroupCreation'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {OverallBudget} from './OverallBudget'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

export const BudgetControl = () => {

	const {user} = useUser()
	const {groups} = useGroupsContext()
	const {toggleDrawer, displayDrawerCreateGroup, displayDrawerEditOverallBudget} = useToggleDrawerContext()
	const {overallBudget, removeBudget} = useBudgetsContext()

	const [showBudgetDeletionDialog, setShowBudgetDeletionDialog] = useState<boolean>(false)

	const deleteOverallBudgetHandler = () => {
		TrackActionEvent('Budget', user?.id, {action: 'remove', type: 'overall'})
		setShowBudgetDeletionDialog(false)
		if (overallBudget) removeBudget(overallBudget)
	}

	return <Grid container className='budgetControlContainer'>
		<Grid item xs={12}>
			<Typography variant='h3' className='title'>Budget Control</Typography>
			<Typography variant='subtitle2' className='subtitle'>Define budgets for the organization and teams.</Typography>
		</Grid>
		<Grid item xs={12}>
			<Box className='organizationBudgetContainer'>
				<Box className='budgetIconContainer'>
					<FunctionsIcon fontSize='large'/>
				</Box>
				<Box className='budgetLimitContainer'>
					<Typography className='title'>Organisation budget</Typography>
					<Typography className='subtitle'>
						{overallBudget ? overallBudget.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : <>
							Unlimited <Tooltip title='If the overall budget for the workspace is not defined, it will be unlimited for employees with access to AI in Narus'
							                   placement='top'>
							<InfoIcon/>
						</Tooltip></>}</Typography>
					{overallBudget ? <Typography className='renewedBudget'>Budget renewed: {formatDateStringToLocaleDate(overallBudget.periodStartDate)} </Typography> : <></>}
				</Box>
				{overallBudget ? <Box className='deleteBudgetContainer'>
					<IconButton className='deleteIcon' onClick={() => setShowBudgetDeletionDialog(true)}>
						<DeleteIcon />
					</IconButton>
				</Box> : <></>}
			</Box>
		</Grid>
		<Grid item xs={12} className='buttonsRow'>
			<Button disabled={!groups.length} variant='outlined' className={!groups.length ? 'disabled' : ''}>Set team budget</Button>
			<Button variant='contained' onClick={toggleDrawer(true, 'EDIT_OVERALL_BUDGET')}>Organization budget</Button>
		</Grid>
		<Grid item xs={12}>
			{!groups.length ? <EmptyGroups>
				<Box className='noTeamsButtonContainer'>
					<Button variant='outlined' onClick={toggleDrawer(true, 'CREATE_GROUP_DRAWER')}>Create a new team</Button>
					<Button variant='contained' onClick={toggleDrawer(true, 'EDIT_OVERALL_BUDGET')}>Organization budget</Button>
				</Box>
			</EmptyGroups> : <></>}
		</Grid>
		<Drawer anchor='right' open={displayDrawerCreateGroup} onClose={toggleDrawer(false, 'CREATE_GROUP_DRAWER')}>
			<GroupCreation/>
		</Drawer>

		<Drawer anchor='right' open={displayDrawerEditOverallBudget} onClose={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')}>
			<OverallBudget/>
		</Drawer>
		<ConfirmationDialog handleClose={() => setShowBudgetDeletionDialog(false)} open={showBudgetDeletionDialog}
		                    currentEvent='overallBudgetDeletion' handleDelete={deleteOverallBudgetHandler}/>
	</Grid>

}