import React, {ReactNode} from 'react'
import {Box, Button, Typography} from '@mui/material'
import './EmptyGroups.scss'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import {ReactComponent as EmptyGroupsIcon} from '../../images/empty-teams-icon.svg'

type EmptyGroupsProps = {
    children?: ReactNode
}

export const EmptyGroups = ({children}: EmptyGroupsProps) => {

    const {toggleDrawer} = useToggleDrawerContext()

    return <Box className='EmptyTeams_Container'>
        {<EmptyGroupsIcon/> ?? <></>}
        <Box className='EmptyTeams_Text'>
            <Typography className='EmptyTeams_Title'>There are no teams in this workspace yet.</Typography>
            <Typography className='EmptyTeams_Description'>Teams let you group your users to quickly and easily manage
                and monitor their activity, and AI access.</Typography>
        </Box>
        {children ?? <Button variant='contained' onClick={toggleDrawer(true, 'CREATE_GROUP_DRAWER')}>Create a new team</Button>}
    </Box>
}