import React, {HTMLAttributes, useState} from 'react'
import {
    Autocomplete,
    Checkbox,
    Divider,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Box
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {AIModelID} from '../../types/AiModel'
import ListItemText from '@mui/material/ListItemText'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useSearchContext} from '../../context/SearchContext'
import {PromptVisibility} from '../../types/Prompt'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
    AutocompleteRenderGetTagProps,
    AutocompleteRenderOptionState
} from '@mui/material/Autocomplete/Autocomplete'
import './SearchLibrary.scss'

interface SearchLibraryProps {
    library: 'Templates' | 'My prompts' | 'Chats' | 'Public prompts' | undefined
    models?: AIModelID[]
    labels: string[]
}

export const SearchLibrary = ({library, models, labels}: SearchLibraryProps) => {

    const {aiModels} = useAiModelsContext()
    const {
        searchText,
        setSearchText,
        setSearchLabels,
        setSearchAIModelIDs,
        searchAIModelIDs,
        setSearchVisibility,
        searchVisibility
    } = useSearchContext()

    const [searchTextFocused, setSearchTextFocused] = useState<boolean>(false)

    const onSearchModelHandler = (selectedAiModelIDs: AIModelID[]) => {
        setSearchAIModelIDs(selectedAiModelIDs)
    }

    const onSearchLabelHandler = (selectedLabels: string[]) => {
        setSearchLabels(selectedLabels)
    }

    const onSearchVisibilityHandler = (selectedVisibility: PromptVisibility[]) => {
        setSearchVisibility(selectedVisibility)
    }

    const getCheckboxLabel = (props: HTMLAttributes<HTMLLIElement>, category: string, state: AutocompleteRenderOptionState) => {
        const {id, ...optionProps} = props

        return (
            <li key={id ?? category} {...optionProps}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize='small'/>}
                    checkedIcon={<CheckBoxIcon fontSize='small'/>}
                    className='searchLibrary_checkboxItem'
                    checked={state.selected}
                />
                {category}
            </li>
        )
    }

    const getRenderTags = (categories: string[], getTagProps: AutocompleteRenderGetTagProps) => {
        const numCategories = categories.length
        const limitCategories = 1
        const numOfAddedLabelsText = numCategories > limitCategories ? ` +${numCategories - limitCategories}` : ''

        return <>
            {categories.slice(0, limitCategories).map((category, index) =>
                <Typography {...getTagProps({index})}
                            variant='body1'>{`${category} ${numOfAddedLabelsText}`}</Typography>
            )}
        </>
    }

    return <Box className='searchLibrary'>
        <TextField placeholder='Search...' variant='filled' type='search' onFocus={() => setSearchTextFocused(true)}
                   className='searchField'
                   onBlur={() => setSearchTextFocused(false)}
                   onChange={event => setSearchText(event.target.value)}
                   InputProps={{startAdornment: <InputAdornment position='start'><SearchIcon/></InputAdornment>}}
                   InputLabelProps={{shrink: searchText !== '' || searchTextFocused}} value={searchText}
        />
        <Divider orientation='vertical' flexItem className='searchDivider'/>
        {library !== 'Templates' && library !== 'Public prompts' ?
            <FormControl className='searchLibrary_dropdownWrapper'>
                <InputLabel id='model-label'>Model</InputLabel>
                <Select value={searchAIModelIDs} label='Model' multiple labelId='model-label'
                        onChange={event => onSearchModelHandler(event.target.value as AIModelID[])}
                        renderValue={(selected) => selected.map(modelId => aiModels?.find(({id}) => id === modelId)?.name).join(', ')}
                >
                    {models?.length ? models.map(modelId => <MenuItem key={modelId} value={modelId}>
                            <Checkbox checked={searchAIModelIDs.indexOf(modelId) > -1}/>
                            <ListItemText primary={aiModels?.find(({id}) => id === modelId)?.name}/>
                        </MenuItem>) :
                        <MenuItem disabled value=''><em>No models defined</em></MenuItem>}
                </Select>
            </FormControl> : <></>}
        <FormControl
            className={`searchLibrary_dropdownWrapper ${library === 'Chats' || library === 'My prompts' ? 'searchLibrary_dropdownWrapperMargin' : ''}`}>
            <Autocomplete
                multiple
                options={labels}
                disableCloseOnSelect
                onChange={(_, categoriesValue) => onSearchLabelHandler(categoriesValue)}
                getOptionLabel={category => category}
                renderTags={getRenderTags}
                renderInput={(params) => <TextField {...params} label='Category'/>}
                renderOption={getCheckboxLabel}
            />
        </FormControl>
        {library === 'My prompts' ? <FormControl className='searchLibrary_dropdownWrapper'>
            <InputLabel id='visibility-label'>Visibility</InputLabel>
            <Select label='Visibility' multiple value={searchVisibility} labelId='visibility-label'
                    onChange={event => onSearchVisibilityHandler(event.target.value as unknown as PromptVisibility[])}
                    renderValue={(selected) => selected.join(' and ')}
            >
                <MenuItem key='public' value='public'>
                    <Checkbox checked={searchVisibility.includes('public')}/>
                    <ListItemText primary='Public'/>
                </MenuItem>
                <MenuItem key='private' value='private'>
                    <Checkbox checked={searchVisibility.includes('private')}/>
                    <ListItemText primary='Private'/>
                </MenuItem>
            </Select>
        </FormControl> : <></>}
    </Box>
}