import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useUserContext} from './UserContext'
import {getUserHasBudgetRemaining} from '../service/persistenceService'

type UserBudgetRemainingContextValue = {
	budgetRemaining: boolean
}

const DEFAULT_USER_BUDGET_REMAINING_CONTEXT: UserBudgetRemainingContextValue = {
	budgetRemaining: false
}

export const UserBudgetRemainingContext = createContext<UserBudgetRemainingContextValue>(DEFAULT_USER_BUDGET_REMAINING_CONTEXT)

export const useUserBudgetRemaining = () => useContext(UserBudgetRemainingContext)

export const UserBudgetRemainingContextProvider: FC<PropsWithChildren> = ({children}) => {
	const {token} = useUserContext()

	const [budgetRemaining, setBudgetRemaining] = useState<boolean>(false)

	const value: UserBudgetRemainingContextValue = useMemo(() => ({
		budgetRemaining,
	}), [budgetRemaining])

	const getUserBudgetRemaining = useCallback(async () => {
		const budgetRemaining = await getUserHasBudgetRemaining(token)
		setBudgetRemaining(budgetRemaining)
	}, [token])

	useEffect(() => {
		getUserBudgetRemaining()
	}, [getUserBudgetRemaining])

	return <UserBudgetRemainingContext.Provider value={value}>
		{children}
	</UserBudgetRemainingContext.Provider>
}