import {Dispatch, MouseEvent, SetStateAction, useState} from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useNavigate} from "react-router-dom"
import {Prompt} from "../../types/Prompt"
import './PromptCard.scss'
import {PromptCardProps} from '../../types/PromptCardProps'
import FavouriteIcon from '../favouriteIcon/FavouriteIcon'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import {CardLabels} from '../cardLabel/CardLabel'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useUserContext} from '../../context/UserContext'
import ConfirmationDialog from '../promptForm/confirmationDialog/ConfirmationDialog'
import {AiModelSelector} from '../aiModelSelector/AiModelSelector'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {AIModelID, DEFAULT_SELECTED_MODELS} from '../../types/AiModel'
import {getSelectedModelIds, isAnyModelSelected} from '../../utils/aiModelUtils'
import {Box, IconButton} from '@mui/material'
import {usePromptDeletion} from '../../hooks/usePromptDeletion'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useUserGroupContext} from '../../context/UserGroupContext'
import {useAiModelsContext} from '../../context/AIModelsContext'

const PromptCard = ({prompt}: PromptCardProps) => {

	const navigate = useNavigate()
	const {selectedTab, token} = useUserContext()
	const {userInfo} = useUserInfoContext()
	const {userGroup} = useUserGroupContext()
	const {aiModels} = useAiModelsContext()
	const {user} = useUser()
	const {setValueHandler, setModelIds} = usePromptCreationContext()
	const {deletePrompt} = usePromptDeletion(token)

	const isUserPrompt = prompt.userId === user?.id

	const [showExecutePromptAiModelSelector, setShowExecutePromptAiModelSelector] = useState(false)
	const [showLoadPromptAiModelSelector, setShowLoadPromptAiModelSelector] = useState(false)
	const [showDeletePromptDialog, setShowDeletePromptDialog] = useState(false)
	const [modelsSelected, setModelsSelected] = useState<Record<AIModelID, boolean>>({
		...DEFAULT_SELECTED_MODELS,
		...(prompt.modelId && {[prompt.modelId]: isModelEnabledForUser(prompt.modelId, aiModels, userInfo, userGroup)})
	})

	const showPrompt = (prompt: Prompt) => {
		if (!isAnyModelSelected(modelsSelected)) return
		const models = getSelectedModelIds(modelsSelected)
		setValueHandler(setModelIds, models, 'modelIds')
		TrackActionEvent('Prompt', user?.id, {
			action: 'view',
			origin: getHomeTabForAnalytics(selectedTab),
			prompt_id: prompt.id
		})
		navigate(`/prompt/${prompt.id}/${models.join(',')}`)
	}

	const showPromptAndGenerateOutputHandler = () => {
		if (!isAnyModelSelected(modelsSelected)) return
		const models = getSelectedModelIds(modelsSelected)
		setValueHandler(setModelIds, models, 'modelIds')
		const {id, tone, audience, format, modelId: model, temperature, frequencyPenalty, language} = prompt

		TrackActionEvent('Run prompt', user?.id, {
			action: 'run_from_home',
			prompt_id: id,
			model,
			tone,
			audience,
			format,
			temperature,
			language,
			frequency_penalty: frequencyPenalty
		})
		navigate(`/prompt/${prompt.id}/execute/${models.join(',')}`)
	}

	const handleDeletePrompt = () => {
		if (prompt.id) {
			deletePrompt(prompt.id)
		}
	}

	const closeDialogHandler = (setterAiModelSelector: Dispatch<SetStateAction<boolean>>) => {
		TrackActionEvent('AI model selector for saved prompt', user?.id, {action: 'close'})
		setterAiModelSelector(false)
	}

	const generateOutputHandler = (event) => {
		event.stopPropagation()
		TrackActionEvent('AI model selector for saved prompt', user?.id, {action: 'open_execute'})
		setShowExecutePromptAiModelSelector(true)
	}

	const showPromptHandler = (event) => {
		event.stopPropagation()
		TrackActionEvent('AI model selector for saved prompt', user?.id, {action: 'open_show'})
		setShowLoadPromptAiModelSelector(true)
	}

	const handleDeleteIconClicked = (event: MouseEvent) => {
		event.stopPropagation()
		TrackActionEvent('Prompt', user?.id, {
			action: 'open_delete',
			origin: getHomeTabForAnalytics(selectedTab),
			prompt_id: prompt.id
		})
		setShowDeletePromptDialog(true)
	}

	return <>
		<Card className='promptCard' onClick={showPromptHandler}>
			<div className='promptCardInfo'>
				<CardContent className='promptCardTitle'>
					<Typography className='promptCardTitleText' variant='inherit' component='div'>
						{prompt.title}
					</Typography>
					<Box className='promptCardIcons'>
						{isUserPrompt ? <IconButton onClick={handleDeleteIconClicked}><DeleteIcon/></IconButton> : <></>}
						{!prompt.isTemplate ? <FavouriteIcon prompt={prompt}/> : <></>}
					</Box>
				</CardContent>
				<CardContent className='promptCardLabels'>
					<CardLabels labels={prompt.labels}/>
				</CardContent>
				<CardContent className='promptCardDescription'>
					<Typography className='promptCardText promptCardDescriptionText' variant='inherit' component='div'>
						{prompt.description}
					</Typography>
				</CardContent>
			</div>
			<div className='promptCardHorizontalLine'></div>
			<CardActions className='promptCardButtons'>
				<div className='promptCardIconButton'>
					<AutoModeIcon className='promptCardIcon'/>
					<Button className='promptCardButton'
					        onClick={generateOutputHandler}>Generate Output</Button>
				</div>
			</CardActions>
		</Card>
		<ConfirmationDialog handleClose={() => closeDialogHandler(setShowExecutePromptAiModelSelector)} open={showExecutePromptAiModelSelector}
		                    handleConfirmAction={showPromptAndGenerateOutputHandler}
		                    currentEvent='executeSavedPrompt'>
			<AiModelSelector customModelsSelected={modelsSelected} setCustomModelsSelected={setModelsSelected}/>
		</ConfirmationDialog>

		<ConfirmationDialog handleClose={() => closeDialogHandler(setShowLoadPromptAiModelSelector)} open={showLoadPromptAiModelSelector}
		                    handleConfirmAction={() => showPrompt(prompt)}
		                    currentEvent='executeSavedPrompt'>
			<AiModelSelector customModelsSelected={modelsSelected} setCustomModelsSelected={setModelsSelected}/>
		</ConfirmationDialog>
		<ConfirmationDialog currentEvent='promptDeletion' open={showDeletePromptDialog} handleClose={() => closeDialogHandler(setShowDeletePromptDialog)}
		                    handleDelete={handleDeletePrompt}/>
	</>

}

export default PromptCard
