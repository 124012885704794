import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {MenuItem, MenuList} from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import GroupIcon from '@mui/icons-material/Group'
import './ConfigurationAdmin.scss'
import {AiModelsControl} from './aiModels/AiModelsControl'
import {UsersList} from './users/UsersList'
import {TrackActionEvent} from '../../../service/SegmentService'
import { useUser } from '@clerk/clerk-react'
import {KnowledgeBaseConfig} from './knowledgeBaseConfig/KnowledgeBaseConfig'
import SettingsIcon from '@mui/icons-material/Settings'
import {GlobalSettings} from './settings/GlobalSettings'
import LockIcon from '@mui/icons-material/Lock'
import {Safeguard} from './safeguard/Safeguard'
import Groups2Icon from '@mui/icons-material/Groups2'
import {Groups} from './groups/Groups'
import {useLocation} from 'react-router'
import PaymentsIcon from '@mui/icons-material/Payments'
import {BudgetControl} from './budget/BudgetControl'

type NavigationTab = 'UserList' | 'KnowledgeBase' | 'AiModelsControl' | 'GlobalSettings' | 'Safeguard' | 'Groups' | 'Budget'

const NavigationTabMap: { [key in NavigationTab]: () => React.JSX.Element } = {
	GlobalSettings: () => <GlobalSettings/>,
	AiModelsControl: () => <AiModelsControl/>,
	UserList: () => <UsersList/>,
	KnowledgeBase: () => <KnowledgeBaseConfig/>,
	Safeguard: () => <Safeguard/>,
	Groups: () => <Groups/>,
	Budget: () => <BudgetControl/>
}

export const ConfigurationAdmin = () => {

	const {user} = useUser()
	const {pathname} = useLocation()
	const [selectedTab, setSelectedTab] = useState<NavigationTab>('GlobalSettings')

	const RenderedComponent = NavigationTabMap[selectedTab]

	const changeTab = (tabName: NavigationTab) => {
		TrackActionEvent('Admin configuration tab', user?.id, {tab: tabName})
		setSelectedTab(tabName)
	}

	useEffect(() => {
		if (pathname.startsWith('/admin/configuration/group/')) {
			setSelectedTab('Groups')
		} else if (pathname === '/admin/configuration/budget') {
			setSelectedTab('Budget')
		}
	}, [pathname])

	return <Box className='configurationContainer'>
		<Box className='configurationSideMenu'>
			<MenuList>
				<MenuItem className={`configurationItem ${selectedTab === 'GlobalSettings' ? 'selected' : ''}`} onClick={() => changeTab('GlobalSettings')}>
					<ListItemIcon>
						<SettingsIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText className='configurationTextItem'>Global settings</ListItemText>
				</MenuItem>
				<MenuItem className={`configurationItem ${selectedTab === 'AiModelsControl' ? 'selected' : ''}`} onClick={() => changeTab('AiModelsControl')}>
					<ListItemIcon>
						<AutoAwesomeIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText className='configurationTextItem'>AI models</ListItemText>
				</MenuItem>
				<MenuItem className={`configurationItem ${selectedTab === 'Budget' ? 'selected' : ''}`} onClick={() => changeTab('Budget')}>
					<ListItemIcon>
						<PaymentsIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText className='configurationTextItem'>Budget Control</ListItemText>
				</MenuItem>
				<MenuItem className={`configurationItem ${selectedTab === 'UserList' ? 'selected' : ''}`} onClick={() => changeTab('UserList')}>
					<ListItemIcon>
						<GroupIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText className='configurationTextItem'>User access</ListItemText>
				</MenuItem>
				<MenuItem className={`configurationItem ${selectedTab === 'Groups' ? 'selected' : ''}`} onClick={() => changeTab('Groups')}>
					<ListItemIcon>
						<Groups2Icon fontSize='small'/>
					</ListItemIcon>
					<ListItemText className='configurationTextItem'>Teams</ListItemText>
				</MenuItem>
				<MenuItem className={`configurationItem ${selectedTab === 'Safeguard' ? 'selected' : ''}`} onClick={() => changeTab('Safeguard')}>
					<ListItemIcon>
						<LockIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText className='configurationTextItem'>Safeguard</ListItemText>
				</MenuItem>
				{/*<MenuItem className={`configurationItem ${selectedTab === 'KnowledgeBase' ? 'selected' : ''}`} onClick={() => changeTab('KnowledgeBase')}>*/}
				{/*	<ListItemIcon>*/}
				{/*		<ConfluenceLogo height={20} width={20}/>*/}
				{/*	</ListItemIcon>*/}
				{/*	<ListItemText>Knowledge base</ListItemText>*/}
				{/*</MenuItem>*/}
			</MenuList>
		</Box>
		<RenderedComponent/>
	</Box>
}