import {getUsageStatistics} from '../service/persistenceService'
import {useUserContext} from '../context/UserContext'
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {UsageStatistic, UserUsageStatistic} from '../types/UserUsageStatistic'
import {useFeedbackContext} from '../context/FeedbackContext'
import {AIModel, AIModelID, AIModels} from '../types/AiModel'
import {roundTwoDecimals} from '../helpers/NumberHelpers'
import {DateRange} from 'rsuite/DateRangePicker'
import {useUsersAccessContext} from '../context/UsersAccessContext'
import {UserInfo} from '../types/UserInfo'
import {useAiModelsContext} from '../context/AIModelsContext'

export type StatisticByModel = {
	name: string
	value: number
}

export type AIModelCost = {
	modelName: string
	modelId: AIModelID
	cost: number
}

type UseStatisticsValue = {
	loading: boolean
	usageStatistics: UsageStatistic | undefined
	top5UsersStatistics: UserUsageStatistic[]
	totalPromptsByModel: StatisticByModel[]
	totalUsersByModel: StatisticByModel[]
	top5ModelsByCost: AIModelCost[]
	setDateRangeFilter: Dispatch<SetStateAction<DateRange | undefined>>
}

export const useStatistics = (): UseStatisticsValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {aiModels, loading: aiModelsLoading} = useAiModelsContext()
	const {usersInfo, loading: userAccessLoading} = useUsersAccessContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [usageStatistics, setUsageStatistics] = useState<UsageStatistic | undefined>(undefined)
	const [top5UsersStatistics, setTop5UsersStatistics] = useState<UserUsageStatistic[]>([])
	const [totalPromptsByModel, setTotalPromptsByModel] = useState<StatisticByModel[]>([])
	const [totalUsersByModel, setTotalUsersByModel] = useState<StatisticByModel[]>([])
	const [top5ModelsByCost, setTop5ModelsByCost] = useState<AIModelCost[]>([])
	const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>(undefined)

	const calculateTop5Users = (userUsageStatistics: UserUsageStatistic[]) => {
		if (!userUsageStatistics.length) return

		const sortedUsersByUsage = userUsageStatistics.sort((userUsageStatistic: UserUsageStatistic, userUsageStatistic2: UserUsageStatistic) =>
			userUsageStatistic2.totalPrompts - userUsageStatistic.totalPrompts)
		setTop5UsersStatistics(sortedUsersByUsage.slice(0, 5))
	}

	const getTop3AIModelsByCost = useCallback((modelsCosts: Record<AIModelID, number>) => {
		const currentAiModels = aiModels.map(aiModel => ({name: aiModel.name, id: aiModel.id}))
		const modelsArray: AIModelCost[] = Object.entries(modelsCosts).map(([modelId, cost]) => ({
			modelId: modelId as AIModelID,
			modelName: currentAiModels?.find(({id}) => id === modelId)?.name ?? AIModels[modelId as AIModelID].name,
			cost: roundTwoDecimals(cost)
		}))

		const sortedModels = modelsArray.sort((modelA, modelB) => modelB.cost - modelA.cost)
		setTop5ModelsByCost(sortedModels.slice(0, 5))
	}, [aiModels])

	const calculateStatisticsByModel = (userUsageStatistics: UserUsageStatistic[], usersInfo: UserInfo[], aiModels: AIModel[]) => {
		if (!userUsageStatistics.length) return
		const totalPromptExecutionsByModel: Record<AIModelID, number> = {} as Record<AIModelID, number>
		const totalUsersStatisticsByModel: Record<AIModelID, number> = {} as Record<AIModelID, number>
		aiModels.forEach(model => {
			totalPromptExecutionsByModel[model.id] = 0
			totalUsersStatisticsByModel[model.id] = usersInfo.length
		})
		userUsageStatistics.forEach(userUsageStatistic => {
			aiModels.forEach(aiModel => {
				totalPromptExecutionsByModel[aiModel.id] += userUsageStatistic.totalPromptsByModel[aiModel.id] || 0
			})
		})

		aiModels.forEach(aiModel => {
			totalUsersStatisticsByModel[aiModel.id] -= usersInfo.filter(userInfo => userInfo.disabledAIModels?.includes(aiModel.id)).length ?? 0
		})

		const totalPromptsByModel = aiModels.map(model => ({name: model.name, value: totalPromptExecutionsByModel[model.id]}))
		setTotalPromptsByModel(totalPromptsByModel)
		const totalUsersByModel = aiModels
			.filter(model => model.id !== AIModelID.AMAZON_Q)
			.map(model => ({name: model.name, value: totalUsersStatisticsByModel[model.id]}))
		setTotalUsersByModel(totalUsersByModel.sort((a, b) => b.value - a.value))
	}

	const getStatistics = useCallback((dateRangeFilter: DateRange | undefined, usersInfo: UserInfo[], aiModels: AIModel[]) => {
		getUsageStatistics(token, dateRangeFilter).then(data => {
			const {userUsageStatistics, modelCostStatistics: {totalCostByModel}} = data
			setLoading(false)

			calculateTop5Users(userUsageStatistics)
			calculateStatisticsByModel(userUsageStatistics, usersInfo, aiModels)
			getTop3AIModelsByCost(totalCostByModel)
			setUsageStatistics(data)
		}).catch((error) => {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setUsageStatistics(undefined)
		}).finally(() => setLoading(false))
	}, [token, showFeedback, getTop3AIModelsByCost])

	useEffect(() => {
		if (!userAccessLoading && !aiModelsLoading) getStatistics(dateRangeFilter, usersInfo, aiModels)
	}, [getStatistics, dateRangeFilter, usersInfo, userAccessLoading, aiModelsLoading, aiModels])

	return {
		loading,
		usageStatistics,
		top5UsersStatistics,
		totalPromptsByModel,
		totalUsersByModel,
		top5ModelsByCost,
		setDateRangeFilter
	}
}
