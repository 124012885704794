import {FC, useEffect} from 'react'
import {Grid} from '@mui/material'
import {AuditingLogTrail} from './AuditingLogTrail'
import {AuditingLogDetailHeader} from './AuditingLogDetailHeader'
import {AuditingLogDetailInfo} from './AuditingLogDetailInfo'
import {AuditingLogDetailList} from './AuditingLogDetailList'
import './AuditingLogDetail.scss'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useNavigate, useParams} from 'react-router-dom'
import {useUser} from '@clerk/clerk-react'
import {useAuditingLogsContext} from '../../../context/AuditingLogsContext'

export const AuditingLogDetail: FC = () => {

    const {logId} = useParams()
    const navigate = useNavigate()
    const {user} = useUser()
    const {viewAuditingLog, auditingLogs} = useAuditingLogsContext()

    const auditingLog = auditingLogs.find(log => log.hashKey === logId)

    const goBack = () => {
        TrackActionEvent('Audit log details', user?.id, {action: 'go_back'})
        navigate(-1)
    }

    useEffect(() => {
        if (auditingLog && !auditingLog.isViewed) {
            viewAuditingLog(auditingLog)
        }
    }, [viewAuditingLog, auditingLog])

    return <>
        <Grid item xs={12} className='auditingLogDetailContainer'>
            <AuditingLogDetailHeader onBack={goBack}/>
        </Grid>
        {auditingLog && <>
            <Grid item xs={12} className='auditingLogDetailContainer auditDataRow' container>
                <AuditingLogDetailInfo auditLog={auditingLog}/>
            </Grid>
            <Grid item xs={12} className='auditingLogsContentContainer'>
                <Grid className='auditingLogDetailContainer auditLogsContainer'>
                    <AuditingLogDetailList auditLog={auditingLog}/>
                </Grid>
                <Grid className='auditingLogDetailContainer auditTrailContainer'>
                    <AuditingLogTrail auditLog={auditingLog}/>
                </Grid>
            </Grid>
        </>
        }
    </>
}
