export type OpenAIApiKey = string


export interface AIModel {
    id: AIModelID
    name: string
    tokenLimit: number
    isEnabled?: boolean
}

export enum AIModelID {
	GPT_3_5 = 'gpt-3.5-turbo',
	GPT_4 = 'gpt-4-0125-preview',
	GTP_4_O = 'gpt-4o',
	GPT_4_O_MINI = 'gpt-4o-mini',
	GOOGLE_GEMINI = 'GoogleGemini',
	GOOGLE_GEMINI_1_5_FLASH = 'GoogleGemini-1.5-flash',
	GOOGLE_GEMINI_1_5_PRO = 'GoogleGemini-1.5-pro',
	GOOGLE_GEMMA_2 = 'GoogleGemma2',
	LLAMA_2 = 'Llama-2',
	LLAMA_3 = 'Llama-3',
	LLAMA_3_1 = 'Llama-3.1',
	MISTRAL = 'Mistral',
	AMAZON_Q = 'Amazon-Q',
	CLAUDE = 'claude-3-haiku-20240307',
	CLAUDE_3_5 = 'claude-3-5-sonnet-20240620'
}

export const AIModels: Record<AIModelID, AIModel> = {
	[AIModelID.GPT_3_5]: {
		id: AIModelID.GPT_3_5,
		name: 'GPT-3.5',
		tokenLimit: 4096 
	}, 
	[AIModelID.GPT_4]: {
		id: AIModelID.GPT_4,
		name: 'GPT-4',
		tokenLimit: 4096 
	}, 
	[AIModelID.GTP_4_O]: {
		id: AIModelID.GTP_4_O,
		name: 'GPT-4o',
		tokenLimit: 4096 
	}, 
	[AIModelID.GPT_4_O_MINI]: {
		id: AIModelID.GPT_4_O_MINI,
		name: 'GPT-4o mini',
		tokenLimit: 4096 
	}, 
	[AIModelID.GOOGLE_GEMINI]: {
		id: AIModelID.GOOGLE_GEMINI,
		name: 'Google Gemini',
		tokenLimit: 32_000 
	}, 
	[AIModelID.GOOGLE_GEMINI_1_5_FLASH]: {
		id: AIModelID.GOOGLE_GEMINI_1_5_FLASH,
		name: 'Google Gemini 1.5 Flash',
		tokenLimit: 32_000 
	}, 
	[AIModelID.GOOGLE_GEMINI_1_5_PRO]: {
		id: AIModelID.GOOGLE_GEMINI_1_5_PRO,
		name: 'Google Gemini 1.5 Pro',
		tokenLimit: 32_000 
	}, 
	[AIModelID.GOOGLE_GEMMA_2]: {
		id: AIModelID.GOOGLE_GEMMA_2,
		name: 'Google Gemma 2',
		tokenLimit: 32_000 
	}, 
	[AIModelID.LLAMA_2]: {
		id: AIModelID.LLAMA_2,
		name: 'Llama 2',
		tokenLimit: 4096 
	}, 
	[AIModelID.LLAMA_3]: {
		id: AIModelID.LLAMA_3,
		name: 'Llama 3',
		tokenLimit: 4096 
	},
	[AIModelID.LLAMA_3_1]: {
		id: AIModelID.LLAMA_3_1,
		name: 'Llama 3.1',
		tokenLimit: 4096 
	},
	[AIModelID.MISTRAL]: {
		id: AIModelID.MISTRAL,
		name: 'Mistral',
		tokenLimit: 8_192 
	}, 
	[AIModelID.AMAZON_Q]: {
		id: AIModelID.AMAZON_Q,
		name: 'Adaptavist Confluence',
		tokenLimit: 32_000 
	}, 
	[AIModelID.CLAUDE]: {
		id: AIModelID.CLAUDE,
		name: 'Claude 3',
		tokenLimit: 4096 
	}, 
	[AIModelID.CLAUDE_3_5]: {
		id: AIModelID.CLAUDE_3_5,
		name: 'Claude 3.5',
		tokenLimit: 4096
	}
}

export const allAIModels = [
    AIModels[AIModelID.GPT_3_5],
    AIModels[AIModelID.GPT_4],
    AIModels[AIModelID.GTP_4_O],
    AIModels[AIModelID.GPT_4_O_MINI],
    AIModels[AIModelID.GOOGLE_GEMINI],
    AIModels[AIModelID.GOOGLE_GEMINI_1_5_FLASH],
    AIModels[AIModelID.GOOGLE_GEMINI_1_5_PRO],
    AIModels[AIModelID.GOOGLE_GEMMA_2],
    AIModels[AIModelID.LLAMA_2],
    AIModels[AIModelID.LLAMA_3],
    AIModels[AIModelID.LLAMA_3_1],
    AIModels[AIModelID.MISTRAL],
    AIModels[AIModelID.AMAZON_Q],
    AIModels[AIModelID.CLAUDE],
    AIModels[AIModelID.CLAUDE_3_5],
] as const

export const ALL_AI_MODEL_IDS: readonly AIModelID[] = [
    AIModelID.GPT_3_5,
    AIModelID.GPT_4,
    AIModelID.GTP_4_O,
    AIModelID.GPT_4_O_MINI,
    AIModelID.GOOGLE_GEMINI,
    AIModelID.GOOGLE_GEMINI_1_5_FLASH,
    AIModelID.GOOGLE_GEMINI_1_5_PRO,
    AIModelID.GOOGLE_GEMMA_2,
    AIModelID.LLAMA_2,
    AIModelID.LLAMA_3,
    AIModelID.LLAMA_3_1,
    AIModelID.MISTRAL,
    AIModelID.AMAZON_Q,
    AIModelID.CLAUDE,
    AIModelID.CLAUDE_3_5,
] as const

export const DISABLED_MODEL_IDS: readonly AIModelID[] = [
    AIModelID.AMAZON_Q,
] as const

export const DEFAULT_SELECTED_MODELS: Record<AIModelID, boolean> = {
    [AIModelID.GPT_3_5]: false,
    [AIModelID.GPT_4]: false,
    [AIModelID.GTP_4_O]: false,
    [AIModelID.GPT_4_O_MINI]: false,
    [AIModelID.GOOGLE_GEMINI]: false,
    [AIModelID.GOOGLE_GEMINI_1_5_FLASH]: false,
    [AIModelID.GOOGLE_GEMINI_1_5_PRO]: false,
    [AIModelID.GOOGLE_GEMMA_2]: false,
    [AIModelID.LLAMA_2]: false,
    [AIModelID.LLAMA_3]: false,
    [AIModelID.LLAMA_3_1]: false,
    [AIModelID.MISTRAL]: false,
    [AIModelID.AMAZON_Q]: false,
    [AIModelID.CLAUDE]: false,
    [AIModelID.CLAUDE_3_5]: false,
} as const

export const AI_MODEL_HEADER_NAMES_MAP: Record<AIModelID, string> = {
    [AIModelID.GPT_3_5]: 'ChatGPT 3.5',
    [AIModelID.GPT_4]: 'ChatGPT 4',
    [AIModelID.GTP_4_O]: 'ChatGPT 4o',
    [AIModelID.GPT_4_O_MINI]: 'ChatGPT 4o mini',
    [AIModelID.GOOGLE_GEMINI]: 'Gemini 1.0 Pro',
    [AIModelID.GOOGLE_GEMINI_1_5_FLASH]: 'Gemini 1.5 Flash',
    [AIModelID.GOOGLE_GEMINI_1_5_PRO]: 'Gemini 1.5 Pro',
    [AIModelID.GOOGLE_GEMMA_2]: 'Gemma 2',
    [AIModelID.LLAMA_2]: 'Llama 2',
    [AIModelID.LLAMA_3]: 'Llama 3',
    [AIModelID.LLAMA_3_1]: 'Llama 3.1',
    [AIModelID.MISTRAL]: 'Mistral',
    [AIModelID.AMAZON_Q]: 'AmazonQ',
    [AIModelID.CLAUDE]: 'Claude',
    [AIModelID.CLAUDE_3_5]: 'Claude 3.5',
} as const