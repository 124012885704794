import {FC} from 'react'
import {useAuditingLogsContext} from '../../context/AuditingLogsContext'
import {hasViewPending} from '../../utils/userAuditLogUtils'
import {Box, Chip} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import './TabLabelUserLogs.scss'

export const TabLabelUserLogs: FC = () => {
	const {auditingLogs} = useAuditingLogsContext()

    const pendingLogsLength = auditingLogs.filter(hasViewPending).length
    const chipText = `Alerts (${pendingLogsLength})`

    return <Box className='TabLabelUserLogs'>
        <span>User Logs</span>
        { pendingLogsLength ? <Chip className='TabLabelUserLogs_Chip' icon={<ErrorIcon/>} label={chipText} /> : <></>}
    </Box>
}