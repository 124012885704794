import {
	Box,
	Card,
	CardContent,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography
} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {CardLabels} from '../cardLabel/CardLabel'
import {useChatsContext} from '../../context/ChatsContext'
import './ChatCards.scss'
import {useNavigate} from 'react-router-dom'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useFeedbackContext} from '../../context/FeedbackContext'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../promptForm/confirmationDialog/ConfirmationDialog'
import {EmptyChats} from '../emptySection/EmptyChats'
import {EmptySection} from '../emptySection/EmptySection'
import {ReactComponent as EmptyPromptsIcon} from '../../images/empty-prompts-icon.svg'
import {SearchLibrary} from '../searchLibrary/SearchLibrary'
import {useSearchContext} from '../../context/SearchContext'
import {filterConversationsByParams} from '../../utils/promptUtils'
import {Chat} from '../../types/Chat'
import {getUniqueValues} from '../../utils/genericUtils'

export const ChatCards = () => {

	const navigate = useNavigate()
	const {user} = useUser()
	const {loading, chatsByUser, deleteChat} = useChatsContext()
	const {showFeedback} = useFeedbackContext()
	const {searchAIModelIDs, searchLabels, searchText} = useSearchContext()

	const [showDeleteChatDialog, setShowDeleteChatDialog] = useState<boolean>(false)
	const [chatId, setChatId] = useState<string>()

	const filteredChats = useMemo(() => filterConversationsByParams(chatsByUser, searchAIModelIDs, searchLabels, searchText) as Chat[]
		, [chatsByUser, searchAIModelIDs, searchLabels, searchText])

	if (!loading && !chatsByUser.length) return <EmptyChats/>

	const onClickHandler = (chatId: string | undefined) => {
		if (!chatId) {
			showFeedback('Can\'t open that chat', 'Refresh and try it again', 'error')
			return
		}
		TrackActionEvent('Chat conversation', user?.id, {action: 'load', chat_id: chatId})
		navigate(`/aiChat/${chatId}`)
	}

	const deleteChatClickHandler = (event: React.MouseEvent, chatId: string | undefined) => {
		event.stopPropagation()
		TrackActionEvent('Delete chat', user?.id, {action: 'delete_icon_pressed'})
		setShowDeleteChatDialog(true)
		setChatId(chatId)
	}

	const deleteChatHandler = (event: React.MouseEvent) => {
		event.stopPropagation()
		if (!chatId) return
		setShowDeleteChatDialog(false)
		deleteChat(chatId).then(() => {
			TrackActionEvent('Delete chat', user?.id, {action: 'confirm_delete'})
			showFeedback('Success', 'The chat was successfully removed.', 'success')
		}).catch((error) => {
			showFeedback('Error', 'Something went wrong fulfilling the action.', 'error')
		})
	}

	const closeDialogHandler = (event: React.MouseEvent) => {
		event.stopPropagation()
		TrackActionEvent('Delete chat', user?.id, {action: 'cancel'})
		setShowDeleteChatDialog(false)
		setChatId(undefined)
	}

	return <Grid container>
		<Grid item xs={12} className='searchContainer'>
			<SearchLibrary library='Chats' models={getUniqueValues(chatsByUser, 'modelId')} labels={getUniqueValues(chatsByUser, 'labels')}/>
		</Grid>
		<Stack className='chatsCardsWrapper' useFlexGap spacing={{xs: 1, sm: 2, md: 4}}>
			{filteredChats.length ? filteredChats
				.map(chat =>
					<Card variant='outlined' key={chat.chatId} className='chatCard' onClick={() => onClickHandler(chat?.chatId)}>
						<CardContent>
							<Box className='chatTitleContainer'>
								<Typography variant='h4' className='chatTitle'>
									{chat.name}
								</Typography>
								<IconButton onClick={(event) => deleteChatClickHandler(event, chat.chatId)} className='deleteIcon'>
									<DeleteIcon/>
								</IconButton>
							</Box>
							<Tooltip title={chat.description} placement='bottom-end'>
								<Typography variant='body2' className='chatDescription'>
									{chat.description}
								</Typography>
							</Tooltip>
							<CardContent className='chatLabels'>
								<CardLabels labels={chat.labels} className='categoryLabel' maxElementsToShow={2}/>
							</CardContent>
						</CardContent>
					</Card>
				) : <EmptySection
						icon={<EmptyPromptsIcon/>}
						title='It looks like there are no matches to your search!'
						description='Try searching for something else or start a new chat with a new prompt.' />
			}

			<ConfirmationDialog handleClose={(event) => closeDialogHandler(event)} open={showDeleteChatDialog}
			                    handleDelete={deleteChatHandler} currentEvent='chatDeletion'/>
		</Stack>
	</Grid>
}