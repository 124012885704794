import React, {useEffect, useMemo} from 'react'
import {PageView} from '../../service/SegmentService'
import {Grid} from '@mui/material'
import HeaderMenu from '../headerMenu/HeaderMenu'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'
import {EmptyPrompts} from '../emptySection/EmptyPrompts'
import {filterConversationsByParams} from '../../utils/promptUtils'
import {useSearchContext} from '../../context/SearchContext'
import {Prompt} from '../../types/Prompt'
import {ReactComponent as EmptyPromptsIcon} from '../../images/empty-prompts-icon.svg'
import {EmptySection} from '../emptySection/EmptySection'


export const MyPrompts = () => {

    const {userPrompts} = usePromptsContext()
    const {searchAIModelIDs, searchLabels, searchText, searchVisibility} = useSearchContext()

    useEffect(() => PageView('My prompts'), [])

    const filteredPrompts = useMemo(() => filterConversationsByParams(userPrompts, searchAIModelIDs, searchLabels, searchText, searchVisibility) as Prompt[]
        , [userPrompts, searchAIModelIDs, searchLabels, searchText, searchVisibility])

    return <Grid container>
        <Grid xs={12} item>
            <HeaderMenu/>
        </Grid>
        <Grid xs={12} item>
            <PromptsCards
                library='My prompts'
                prompts={filteredPrompts}
                showLoadMore={false}
                customEmptyPromptsElement={!userPrompts.length ? <EmptyPrompts/> :
                    !filteredPrompts.length ? <EmptySection
                        icon={<EmptyPromptsIcon/>}
                        title='It looks like there are no matches to your search!'
                        description='Try searching for something else or start a new chat with a new prompt.'/> : undefined}/>
        </Grid>
    </Grid>
}
