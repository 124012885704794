import {FC, PropsWithChildren} from 'react'
import {useLocation} from 'react-router-dom'
import {Grid} from '@mui/material'
import '../../App.scss'

const getClassNameGridSuffix = (pathname: string): string => {
	if (pathname.startsWith('/admin')) {
		return 'admin'
	} else if (pathname.startsWith('/create-account')) {
		return 'account-creation'
	} else if (pathname.startsWith('/pricing')) {
		return 'pricing'
	} else {
		return ''
	}
}
export const AppGridWrapper: FC<PropsWithChildren> = ({
    children
}) => {
	const location = useLocation()

	const classnameGridSuffix = getClassNameGridSuffix(location.pathname)

    return <Grid container spacing={2} className={`App ${classnameGridSuffix}`}>
        { children }
    </Grid>
}