import React from 'react'
import {Box, Grid, Tooltip, Typography} from '@mui/material'
import './CostPerAIModel.scss'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import PaymentsIcon from '@mui/icons-material/Payments'
import {NoDataAvailable} from './NoDataAvailable'
import Spinner from '../../spinner/Spinner'
import {AiModelIcon} from '../../aiModelIcon/AiModelIcon'
import {roundTwoDecimals} from '../../../helpers/NumberHelpers'
import {useBudgetsContext} from '../../../context/BudgetContext'
import {BudgetSpentLinearProgress} from './BudgetSpentLinearProgress'
import ErrorIcon from '@mui/icons-material/Error'

export const CostPerAIModel = () => {

	const {usageStatistics, loading, top5ModelsByCost} = useStatisticsContext()
	const {overallBudget} = useBudgetsContext()


	const percentageSpentBudget = overallBudget ? (overallBudget.spent / overallBudget.amount) * 100 : 0
	const percentageSpentBudgetClassname = percentageSpentBudget >= 100 ? 'goneBudget' : percentageSpentBudget > 85 ? 'mostlySpentBudget' : ''
	return <Box className='expenditureContainer'>
		<Typography className='aiChartTitle expenditure' variant='h3'>Expenditure {percentageSpentBudget >=100 ? <ErrorIcon className='goneBudgetIcon'/> : <></>}</Typography>
		{loading && <Grid item xs={12} className='spinnerContainer'><Spinner/></Grid>}
		{!loading && !usageStatistics?.modelCostStatistics && <NoDataAvailable/>}
		{!loading && usageStatistics?.modelCostStatistics && <>
            <Box className='totalCostContainer'>
                <Box className={`iconContainer ${percentageSpentBudgetClassname}`}>
                    <PaymentsIcon/>
                </Box>
                <Box>
                    <Typography variant='subtitle2'>Current spend</Typography>
                    <Typography variant='h3' className={`expensesNumber ${percentageSpentBudgetClassname}`}>${roundTwoDecimals(usageStatistics.modelCostStatistics.totalCostAllModels)}</Typography>
                </Box>
            </Box>
            {overallBudget ? <BudgetSpentLinearProgress budget={overallBudget} percentageSpentBudget={percentageSpentBudget}/> : <hr className='dividerExpenditure'/> }
            <Box className='breakdownContainer'>
                <Typography className='breakdownTitle' variant='h4'>Top 5 AI</Typography>
                <Grid container rowSpacing={1} className='breakdownTable'>
					{top5ModelsByCost.map(({modelId, modelName, cost}, index) => <Grid item xs={6} md={4} lg={2.4} key={`${modelId}-${index}`}>
							<Box className='aiModelCost'>
								<Box><AiModelIcon modelId={modelId} className={''}/></Box>
								<Tooltip title={modelName}><Typography variant='subtitle2'>{modelName}</Typography></Tooltip>
							</Box>
							<Typography variant='h5'>${cost}</Typography>
						</Grid>
					)}
                </Grid>
            </Box>
        </>}
	</Box>
}