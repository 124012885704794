import {FC, useCallback, useEffect} from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ACCOUNT_CREATION_COMPONENT_MAP, ACCOUNT_CREATION_CONFIG } from '../../constants/AccountCreationConstants'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { StepIndex } from '../../types/AccountCreation'
import './AccountCreationForm.scss'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

type AccountCreationFormProps = {
    index: StepIndex
}

export const AccountCreationForm: FC<AccountCreationFormProps> = ({
    index
}) => {
    const { valid, stepsState, submitStep, form } = useAccountCreationContext()
    const { companyDomains, companyName, adminEmails, models } = form
    const { user } = useUser()

    const { actionTitle, controls, formTitle, validators, pageAnalytics } = ACCOUNT_CREATION_CONFIG[index]
    const state = stepsState[index]
    const loading = state === 'loading'

    const actionButtonText = loading ? 'in progress' : actionTitle
    const actionButtonDisabled = !validators.every(key => valid[key]) || state === 'loading'

    const controlComponents = controls.map(value => {
        const Component = ACCOUNT_CREATION_COMPONENT_MAP[value]
        return <Component key={value}/>
    })

    const handleActionButtonClicked = useCallback(() => {
        const trackMap: Record<StepIndex, () => Promise<void>> = {
            0: () => TrackActionEvent('Create Organization', user?.id, {
                company_name: companyName,
                company_domains: companyDomains,
                action: 'continue'
            }),
            1: () => TrackActionEvent('Admins Defined', user?.id, {
                company_name: companyName,
                company_admin_emails: adminEmails,
                action: 'continue'
            }),
            2: () => TrackActionEvent('AI Models', user?.id, {
                company_name: companyName,
                company_ai_models: models.map(({modelId}) => modelId),
                action: 'finalize'
            })
        }

        trackMap[index]()
        submitStep(index)
    }, [index, submitStep, user?.id, companyName, companyDomains, adminEmails, models])

    useEffect(() => {
        if(state !== 'completed') PageView(pageAnalytics)
    }, [pageAnalytics, state])

    return <Box className='AccountCreationForm'>
        <Typography variant='h3'>{formTitle}</Typography>
        <Box className='AccountCreationForm_Controls'>
            { controlComponents }
        </Box>
        <Box className='AccountCreationForm_Action'>
            <Button variant='contained' className={`${actionButtonDisabled ? 'disabled' : ''}`} disabled={actionButtonDisabled} onClick={handleActionButtonClicked}>
                { actionButtonText }
            </Button>
        </Box>
    </Box>
}