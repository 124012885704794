import {Box, LinearProgress, Typography} from '@mui/material'
import {roundTwoDecimals} from '../../../helpers/NumberHelpers'
import React from 'react'
import {Budget} from '../../../types/Budget'
import './BudgetSpentLinearProgress.scss'
import {formatDateStringToLocaleDate} from '../../../helpers/DateHelpers'

type BudgetSpentLinearProgressProps = {
	budget: Budget
	percentageSpentBudget: number
}

export const BudgetSpentLinearProgress = ({budget, percentageSpentBudget}: BudgetSpentLinearProgressProps) => {
	return <Box className='budgetStatisticsContainer'>
		<Box className='budgetRowStatisticsContainer'>
			<Typography>
				{roundTwoDecimals(percentageSpentBudget)}% ({roundTwoDecimals(budget.spent)})
			</Typography>
			<Typography>
				${budget.amount}
			</Typography>
		</Box>
		<LinearProgress variant="determinate" value={percentageSpentBudget > 100 ? 100 : percentageSpentBudget}
		                className={`budgetProgressBar ${percentageSpentBudget > 100 ? 'goneBudget' : percentageSpentBudget > 85 ? 'mostlySpentBudget' : ''}`}/>
		<Typography className='renewedDate'>
			Budget renewed: {formatDateStringToLocaleDate(budget.periodStartDate)}
		</Typography>
	</Box>
}