import React, {useCallback, useEffect, useState} from 'react'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import HeaderMenu from '../headerMenu/HeaderMenu'
import {Grid, Tab, Tabs} from '@mui/material'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'
import {useUser} from '@clerk/clerk-react'
import './InspirationPrompts.scss'
import {PROMPT_TEMPLATES} from '../../constants/PromptTemplates'
import {useSearchContext} from '../../context/SearchContext'
import {filterConversationsByParams} from '../../utils/promptUtils'

export const InspirationPrompts = () => {

	const {prompts, lastEvaluatedKey} = usePromptsContext()
	const {user} = useUser()
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const {searchText, searchAIModelIDs, searchLabels, setSearchLabels, setSearchText} = useSearchContext()

	useEffect(() => PageView('Inspiration prompts'), [])

	const selectTabHandler = useCallback((newValue: number) => {
		TrackActionEvent('Inspiration prompts', user?.id, {section: ''})
		setSearchLabels([])
		setSearchText('')
		setSelectedTab(newValue)
	}, [user?.id, setSearchLabels, setSearchText])

	const displayPromptsByTab = () => {
		switch (selectedTab) {
			case 0:
				return <PromptsCards
					library='Templates'
					prompts={filterConversationsByParams(PROMPT_TEMPLATES, searchAIModelIDs, searchLabels, searchText, ['public'])}
					showLoadMore={false}
					noPromptsMessage='There are no prompts in this library yet'/>
			case 1:
				return <PromptsCards
					library='Public prompts'
					prompts={filterConversationsByParams(prompts, searchAIModelIDs, searchLabels, searchText, ['public'])}
					showLoadMore={lastEvaluatedKey !== undefined}
					noPromptsMessage='There are no prompts in this library yet'/>
		}
	}

	return <Grid container>
		<Grid xs={12} item>
			<HeaderMenu/>
		</Grid>
		<Grid xs={12} item>
			<Tabs value={selectedTab} onChange={(_, newValue: number) => selectTabHandler(newValue)}
			      className='inspirationTabs' variant='scrollable'>
				<Tab label='Templates'/>
				<Tab label='Public prompts'/>
			</Tabs>
		</Grid>
		<Grid xs={12} item>
			{displayPromptsByTab()}
		</Grid>
	</Grid>
}