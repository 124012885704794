import {StatisticsContextProvider} from '../../context/StatisticsContext'
import {UsersAccessContextProvider} from '../../context/UsersAccessContext'
import {ToggleDrawerContextProvider} from '../../context/ToggleDrawerContext'
import {AiModelsContextProvider} from '../../context/AIModelsContext'
import {AuditingLogsContextProvider} from '../../context/AuditingLogsContext'
import {OrganizationContextProvider} from '../../context/OrganizationContext'
import {AdminEmailsContextProvider} from '../../context/AdminEmailsContext'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {WarningsContextProvider} from '../../context/WarningsContext'
import {GroupsContextProvider} from '../../context/GroupsContext'
import {AdminNavigationContextProvider} from '../../context/AdminNavigationContextProvider'
import {Admin} from './Admin'
import {TrialBoundary} from '../trial/TrialBoundary'
import {ThemeWrapper} from '../theme/ThemeWrapper'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {FeedbackContextProvider} from '../../context/FeedbackContext'
import {StripeSubscriptionsContextProvider} from '../../context/StripeSubscriptionsContext'
import {DataGridContextProvider} from '../../context/DataGridContext'
import {BudgetsContextProvider} from '../../context/BudgetContext'

export const AdminWrapper = () => {
    return (
        <UserOrganizationContextProvider>
            <FeedbackContextProvider>
                <ThemeWrapper>
                    <UserInfoContextProvider>
                        <UsersAccessContextProvider>
                            <StripeSubscriptionsContextProvider>
                                <ToggleDrawerContextProvider>
                                    <AiModelsContextProvider>
                                        <StatisticsContextProvider>
                                            <AuditingLogsContextProvider>
                                                <OrganizationContextProvider>
                                                    <AdminEmailsContextProvider>
                                                        <WarningsContextProvider>
                                                            <GroupsContextProvider>
                                                                <AdminNavigationContextProvider>
                                                                    <TrialBoundary>
                                                                        <DataGridContextProvider>
                                                                            <BudgetsContextProvider>
                                                                                <Admin/>
                                                                            </BudgetsContextProvider>
                                                                        </DataGridContextProvider>
                                                                    </TrialBoundary>
                                                                </AdminNavigationContextProvider>
                                                            </GroupsContextProvider>
                                                        </WarningsContextProvider>
                                                    </AdminEmailsContextProvider>
                                                </OrganizationContextProvider>
                                            </AuditingLogsContextProvider>
                                        </StatisticsContextProvider>
                                    </AiModelsContextProvider>
                                </ToggleDrawerContextProvider>
                            </StripeSubscriptionsContextProvider>
                        </UsersAccessContextProvider>
                    </UserInfoContextProvider>
                </ThemeWrapper>
            </FeedbackContextProvider>
        </UserOrganizationContextProvider>
    )
}